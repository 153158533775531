.lg-medium-zoom-item {
  cursor: zoom-in;
}

.lg-medium-zoom .lg-outer {
  cursor: zoom-out;
}
.lg-medium-zoom .lg-outer.lg-grab img.lg-object {
  cursor: zoom-out;
}
.lg-medium-zoom .lg-outer.lg-grabbing img.lg-object {
  cursor: zoom-out;
}


